// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-careers-maribor-mobile-dev-jsx": () => import("./../../../src/pages/careers/maribor/mobile-dev.jsx" /* webpackChunkName: "component---src-pages-careers-maribor-mobile-dev-jsx" */),
  "component---src-pages-careers-maribor-project-manager-jsx": () => import("./../../../src/pages/careers/maribor/project-manager.jsx" /* webpackChunkName: "component---src-pages-careers-maribor-project-manager-jsx" */),
  "component---src-pages-careers-maribor-qa-tester-jsx": () => import("./../../../src/pages/careers/maribor/qa-tester.jsx" /* webpackChunkName: "component---src-pages-careers-maribor-qa-tester-jsx" */),
  "component---src-pages-careers-maribor-ui-ux-designer-jsx": () => import("./../../../src/pages/careers/maribor/ui-ux-designer.jsx" /* webpackChunkName: "component---src-pages-careers-maribor-ui-ux-designer-jsx" */),
  "component---src-pages-careers-maribor-web-developer-jsx": () => import("./../../../src/pages/careers/maribor/web-developer.jsx" /* webpackChunkName: "component---src-pages-careers-maribor-web-developer-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-case-study-moshi-jsx": () => import("./../../../src/pages/case-study/moshi.jsx" /* webpackChunkName: "component---src-pages-case-study-moshi-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-people-luka-jsx": () => import("./../../../src/pages/people/luka.jsx" /* webpackChunkName: "component---src-pages-people-luka-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-quote-jsx": () => import("./../../../src/pages/quote.jsx" /* webpackChunkName: "component---src-pages-quote-jsx" */),
  "component---src-pages-service-frontend-jsx": () => import("./../../../src/pages/service/frontend.jsx" /* webpackChunkName: "component---src-pages-service-frontend-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

